import { default as _91company_id_934Qcpfi4asrMeta } from "/var/www/www-root/data/www/app/pages/company/[company_id].vue?macro=true";
import { default as indexrdaUCXixIFMeta } from "/var/www/www-root/data/www/app/pages/index.vue?macro=true";
import { default as loginktWeaDFYhlMeta } from "/var/www/www-root/data/www/app/pages/login.vue?macro=true";
import { default as _91case_id_93AwsFAv3jm9Meta } from "/var/www/www-root/data/www/app/pages/projects/[project_id]/economics/[case_id].vue?macro=true";
import { default as compare8agjELspCDMeta } from "/var/www/www-root/data/www/app/pages/projects/[project_id]/economics/compare.vue?macro=true";
import { default as indexTEZ4g6Z5EfMeta } from "/var/www/www-root/data/www/app/pages/projects/[project_id]/economics/index.vue?macro=true";
import { default as _91case_id_9343EzElFGT5Meta } from "/var/www/www-root/data/www/app/pages/projects/[project_id]/engineering/[case_id].vue?macro=true";
import { default as indexrZyazWscDxMeta } from "/var/www/www-root/data/www/app/pages/projects/[project_id]/engineering/index.vue?macro=true";
import { default as index09br4EYiqYMeta } from "/var/www/www-root/data/www/app/pages/projects/[project_id]/index.vue?macro=true";
import { default as _91case_id_938OwrOTL4tBMeta } from "/var/www/www-root/data/www/app/pages/projects/[project_id]/layers/[layer_id]/cases/[case_id].vue?macro=true";
import { default as indexUj1cmU9RXeMeta } from "/var/www/www-root/data/www/app/pages/projects/[project_id]/layers/[layer_id]/index.vue?macro=true";
import { default as index8RUk7HNqe1Meta } from "/var/www/www-root/data/www/app/pages/projects/[project_id]/layers/index.vue?macro=true";
import { default as _91case_id_93Gg6c6fWDBvMeta } from "/var/www/www-root/data/www/app/pages/projects/[project_id]/objects/[object_id]/cases/[case_id].vue?macro=true";
import { default as indexTQ574i2axpMeta } from "/var/www/www-root/data/www/app/pages/projects/[project_id]/objects/[object_id]/index.vue?macro=true";
import { default as indexlCXY43MnFRMeta } from "/var/www/www-root/data/www/app/pages/projects/[project_id]/objects/index.vue?macro=true";
import { default as _91tax_id_93fY4U638GiOMeta } from "/var/www/www-root/data/www/app/pages/projects/[project_id]/taxes/[tax_id].vue?macro=true";
import { default as indexfPsjLqFD8DMeta } from "/var/www/www-root/data/www/app/pages/projects/index.vue?macro=true";
import { default as indexNMzccxqdjsMeta } from "/var/www/www-root/data/www/app/pages/system/index.vue?macro=true";
import { default as jobsGdauZ9fedpMeta } from "/var/www/www-root/data/www/app/pages/system/jobs.vue?macro=true";
import { default as testr7MCFyPaX9Meta } from "/var/www/www-root/data/www/app/pages/test.vue?macro=true";
import { default as _91user_id_93bqTpLBMoU4Meta } from "/var/www/www-root/data/www/app/pages/user/[user_id].vue?macro=true";
export default [
  {
    name: "company-company_id",
    path: "/company/:company_id()",
    meta: _91company_id_934Qcpfi4asrMeta || {},
    component: () => import("/var/www/www-root/data/www/app/pages/company/[company_id].vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/www-root/data/www/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginktWeaDFYhlMeta || {},
    component: () => import("/var/www/www-root/data/www/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "projects-project_id-economics-case_id",
    path: "/projects/:project_id()/economics/:case_id()",
    meta: _91case_id_93AwsFAv3jm9Meta || {},
    component: () => import("/var/www/www-root/data/www/app/pages/projects/[project_id]/economics/[case_id].vue").then(m => m.default || m)
  },
  {
    name: "projects-project_id-economics-compare",
    path: "/projects/:project_id()/economics/compare",
    meta: compare8agjELspCDMeta || {},
    component: () => import("/var/www/www-root/data/www/app/pages/projects/[project_id]/economics/compare.vue").then(m => m.default || m)
  },
  {
    name: "projects-project_id-economics",
    path: "/projects/:project_id()/economics",
    meta: indexTEZ4g6Z5EfMeta || {},
    component: () => import("/var/www/www-root/data/www/app/pages/projects/[project_id]/economics/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-project_id-engineering-case_id",
    path: "/projects/:project_id()/engineering/:case_id()",
    meta: _91case_id_9343EzElFGT5Meta || {},
    component: () => import("/var/www/www-root/data/www/app/pages/projects/[project_id]/engineering/[case_id].vue").then(m => m.default || m)
  },
  {
    name: "projects-project_id-engineering",
    path: "/projects/:project_id()/engineering",
    meta: indexrZyazWscDxMeta || {},
    component: () => import("/var/www/www-root/data/www/app/pages/projects/[project_id]/engineering/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-project_id",
    path: "/projects/:project_id()",
    meta: index09br4EYiqYMeta || {},
    component: () => import("/var/www/www-root/data/www/app/pages/projects/[project_id]/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-project_id-layers-layer_id-cases-case_id",
    path: "/projects/:project_id()/layers/:layer_id()/cases/:case_id()",
    meta: _91case_id_938OwrOTL4tBMeta || {},
    component: () => import("/var/www/www-root/data/www/app/pages/projects/[project_id]/layers/[layer_id]/cases/[case_id].vue").then(m => m.default || m)
  },
  {
    name: "projects-project_id-layers-layer_id",
    path: "/projects/:project_id()/layers/:layer_id()",
    meta: indexUj1cmU9RXeMeta || {},
    component: () => import("/var/www/www-root/data/www/app/pages/projects/[project_id]/layers/[layer_id]/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-project_id-layers",
    path: "/projects/:project_id()/layers",
    meta: index8RUk7HNqe1Meta || {},
    component: () => import("/var/www/www-root/data/www/app/pages/projects/[project_id]/layers/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-project_id-objects-object_id-cases-case_id",
    path: "/projects/:project_id()/objects/:object_id()/cases/:case_id()",
    meta: _91case_id_93Gg6c6fWDBvMeta || {},
    component: () => import("/var/www/www-root/data/www/app/pages/projects/[project_id]/objects/[object_id]/cases/[case_id].vue").then(m => m.default || m)
  },
  {
    name: "projects-project_id-objects-object_id",
    path: "/projects/:project_id()/objects/:object_id()",
    meta: indexTQ574i2axpMeta || {},
    component: () => import("/var/www/www-root/data/www/app/pages/projects/[project_id]/objects/[object_id]/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-project_id-objects",
    path: "/projects/:project_id()/objects",
    meta: indexlCXY43MnFRMeta || {},
    component: () => import("/var/www/www-root/data/www/app/pages/projects/[project_id]/objects/index.vue").then(m => m.default || m)
  },
  {
    name: "projects-project_id-taxes-tax_id",
    path: "/projects/:project_id()/taxes/:tax_id()",
    meta: _91tax_id_93fY4U638GiOMeta || {},
    component: () => import("/var/www/www-root/data/www/app/pages/projects/[project_id]/taxes/[tax_id].vue").then(m => m.default || m)
  },
  {
    name: "projects",
    path: "/projects",
    meta: indexfPsjLqFD8DMeta || {},
    component: () => import("/var/www/www-root/data/www/app/pages/projects/index.vue").then(m => m.default || m)
  },
  {
    name: "system",
    path: "/system",
    meta: indexNMzccxqdjsMeta || {},
    component: () => import("/var/www/www-root/data/www/app/pages/system/index.vue").then(m => m.default || m)
  },
  {
    name: "system-jobs",
    path: "/system/jobs",
    meta: jobsGdauZ9fedpMeta || {},
    component: () => import("/var/www/www-root/data/www/app/pages/system/jobs.vue").then(m => m.default || m)
  },
  {
    name: "test",
    path: "/test",
    meta: testr7MCFyPaX9Meta || {},
    component: () => import("/var/www/www-root/data/www/app/pages/test.vue").then(m => m.default || m)
  },
  {
    name: "user-user_id",
    path: "/user/:user_id()",
    meta: _91user_id_93bqTpLBMoU4Meta || {},
    component: () => import("/var/www/www-root/data/www/app/pages/user/[user_id].vue").then(m => m.default || m)
  }
]